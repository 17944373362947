<template>
    <AppPanel :hasBackButton="true" :location="[title]" backPath="/customers" subtitle="Preencha os campos abaixo">
        <template #content>
            <AppFormCadastro
                :form="form"
                :formData="formData"
                :showSaveAndReturn="true"
                :service="service"
                v-model:runSave="runSave"
                backPath="/customers"
                @onAfterSave="onAfterSave"
                @onBeforeSave="onBeforeSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="clientes-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="">
                        <TabView ref="tabview" scrollable>
                            <TabPanel header="Cadastro do cliente">
                                <div class="grid">
                                    <div class="col-12 md:col-6">
                                        <label for="classificacao">Classificação</label>
                                        <Dropdown
                                            id="classificacao"
                                            v-model="form.classificacao"
                                            :options="classificacaoCliente"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Selecione..."
                                        />
                                    </div>
                                    <div class="col-12 md:col">
                                        <label for="situacao">Situação</label>
                                        <Dropdown
                                            id="situacao"
                                            v-model="form.situacao"
                                            :options="situacaoCliente"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Selecione..."
                                        />
                                    </div>
                                    <div class="col-12 md:col-2">
                                        <div class="flex">
                                            <div class="field mr-3 mt-0">
                                                <label v-if="!form.urlLogo" for="Logo">Logo</label>
                                                <div v-if="form.urlLogo" class="user-photo-template">
                                                    <Image :src="form.urlLogo" class="user-photo" height="70" preview />
                                                </div>
                                            </div>
                                            <div class="field mr-3 mt-0">
                                                <span v-if="form.urlLogo" class="p-button p-component p-button-link" @click="removerLogo">
                                                    <span class="p-button-icon p-button-icon-left pi pi-trash"></span>
                                                    Remover
                                                </span>

                                                <FileUpload
                                                    class="p-button-link"
                                                    id="foto"
                                                    mode="basic"
                                                    accept="image/*"
                                                    :maxFileSize="1000000"
                                                    auto
                                                    customUpload
                                                    @uploader="uploadLogo"
                                                    chooseLabel="Upload"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-6">
                                        <label for="grupos">Grupo</label>
                                        <DropDownGrupo v-model="grupo" :idValue="form.idGrupo"></DropDownGrupo>
                                    </div>
                                    <div class="field col-6">
                                        <label for="produtosServicos">Produtos e Serviços</label>
                                        <MultiSelectProdutosServicos id="produtosServicos" v-model="produtosServicosSelecionados" />
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-6">
                                        <span :style="{ color: 'red' }">*</span>
                                        <label for="name">Nome Fantasia</label>
                                        <InputText
                                            id="name"
                                            v-model.trim="form.name"
                                            :class="{ 'p-invalid': submitted && !form.name }"
                                            autocomplete="off"
                                            maxlength="150"
                                        />
                                        <small v-if="submitted && !form.name" class="p-error">Razão social é obrigatório.</small>
                                    </div>
                                    <div class="field col-6">
                                        <label class="mr-2 ml-0" for="apelido">Apelido</label>
                                        <InputText id="apelido" v-model.trim="form.apelido" autocomplete="off" maxlength="150" />
                                    </div>
                                </div>
                                <div class="field">
                                    <span :style="{ color: 'red' }">*</span>
                                    <label for="trade_name">Razão social</label>
                                    <InputText
                                        id="trade_name"
                                        v-model.trim="form.tradeName"
                                        :class="{ 'p-invalid': submitted && !form.tradeName }"
                                        autocomplete="off"
                                        maxlength="150"
                                        required="true"
                                    />
                                    <small v-if="submitted && !form.tradeName" class="p-error">Razão social é obrigatório.</small>
                                </div>
                                <div class="grid">
                                    <div class="field col-6">
                                        <label for="codigoContrato">Número do contrato</label>
                                        <InputText id="codigoContrato" v-model="form.numeroContrato"></InputText>
                                    </div>
                                    <div class="field col-6">
                                        <label for="dataAssinatura">Data de assinatura do contrato</label>
                                        <Calendar
                                            id="dataHomologacao"
                                            v-model="form.dataAssinaturaContrato"
                                            :showIcon="true"
                                            dateFormat="dd/mm/yy"
                                            selectionMode="single"
                                        />
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-6">
                                        <span :style="{ color: 'red' }">*</span>
                                        <label for="tipo_inscricao">Tipo Inscrição</label>
                                        <Dropdown
                                            showClear
                                            id="tipo_inscricao"
                                            v-model="form.tipoInscricao"
                                            :options="tipoInscricao"
                                            optionLabel="value"
                                            optionValue="value"
                                            placeholder="Selecione..."
                                            @change="onChangeTipoInscricao($event)"
                                        />
                                    </div>
                                    <div class="field col-6">
                                        <span :style="{ color: 'red' }">*</span>
                                        <label for="inscricao">Inscrição</label>
                                        <InputMask
                                            v-if="form.tipoInscricao === 'CNPJ'"
                                            id="inscricao"
                                            v-model.trim="form.inscricao"
                                            :class="{ 'p-invalid': submitted && !form.inscricao }"
                                            autocomplete="off"
                                            mask="99.999.999/9999-99"
                                        />
                                        <InputMask
                                            v-if="form.tipoInscricao === 'CPF'"
                                            id="inscricao"
                                            v-model.trim="form.inscricao"
                                            :class="{ 'p-invalid': submitted && !form.inscricao }"
                                            autocomplete="off"
                                            mask="999.999.999-99"
                                        />
                                        <small v-if="submitted && !form.inscricao" class="p-error">Inscrição é obrigatória.</small>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-6">
                                        <label for="inscricaoEstadual">Inscrição Estadual</label>
                                        <InputText id="inscricaoEstadual" v-model.trim="form.inscricaoEstadual" />
                                    </div>
                                    <div class="field col-6">
                                        <label for="inscricaoMunicipal">Inscrição Municipal</label>
                                        <InputText id="inscricaoMunicipal" v-model.trim="form.inscricaoMunicipal" />
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-10">
                                        <label for="cnaes">CNAE</label>
                                        <DropdownCnae id="cnaes" v-model="form.cnae" @change="setGrauRisco" />
                                    </div>
                                    <div class="field col-2">
                                        <label for="grauRisco">Grau de risco</label>
                                        <Dropdown
                                            showClear
                                            id="grauRisco"
                                            v-model="form.grauRisco"
                                            :options="grauRisco"
                                            optionLabel="value"
                                            optionValue="value"
                                        />
                                    </div>
                                </div>
                                <div class="field">
                                    <label for="orientation">Particularidades</label>
                                    <Textarea v-model="form.particularidades" :autoResize="true" autocomplete="off" rows="3" />
                                </div>
                                <h5 class="mb-4 mt-5">Método de Agendamento</h5>
                                <div class="grid">
                                    <div class="field col-6">
                                        <span :style="{ color: 'red' }">*</span>
                                        <label for="quemAgenda">Quem agenda</label>
                                        <Dropdown
                                            showClear
                                            id="quemAgenda"
                                            v-model="form.quemAgenda"
                                            :class="{ 'p-invalid': submitted && !form.quemAgenda }"
                                            :options="agendaAvisa"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Selecione..."
                                        />
                                        <small v-if="submitted && !form.quemAgenda" class="p-error">É obrigatório informar quem agenda. </small>
                                    </div>
                                    <div class="field col-6">
                                        <span :style="{ color: 'red' }">*</span>
                                        <label for="quemAvisa">Quem avisa</label>
                                        <Dropdown
                                            showClear
                                            id="quemAvisa"
                                            v-model="form.quemAvisa"
                                            :class="{ 'p-invalid': submitted && !form.quemAvisa }"
                                            :options="agendaAvisa"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Selecione..."
                                        />
                                        <small v-if="submitted && !form.quemAvisa" class="p-error">É obrigatório informar quem avisa</small>
                                    </div>
                                </div>
                                <Divider align="center" />
                                <div class="grid">
                                    <div class="field field-checkbox col-6">
                                        <label class="m-2 ml-0" for="pendencias">Pendências financeiras</label>
                                        <InputSwitch id="pendencias" v-model="form.financialOutstanding" />
                                    </div>
                                    <div class="field field-checkbox col-6">
                                        <label class="m-2 ml-0" for="eSocialAtivo">Envia eventos para o eSocial</label>
                                        <InputSwitch id="eSocialAtivo" v-model="form.eSocialAtivo" />
                                    </div>
                                </div>
                                <h5 class="mb-4 mt-5">Dados de integracao</h5>
                                <div class="grid">
                                    <div class="field field-checkbox col-6">
                                        <label class="mr-2 ml-0" for="integraSoc">Integração SOC</label>
                                        <InputSwitch id="integraSoc" v-model="form.integracaoSoc" />
                                    </div>
                                    <div class="field field-checkbox col-6">
                                        <label class="mr-2 ml-0" for="integraTerceiros">Integração com terceiros</label>
                                        <InputSwitch id="integraTerceiros" v-model="form.integracaoTerceiros" />
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-6">
                                        <label class="mr-2" for="code">Código SOC</label>
                                        <InputText
                                            id="code"
                                            v-model.trim="form.code"
                                            :class="{ 'p-invalid': submitted && form.integracaoSoc && !form.code }"
                                            :required="form.integracaoSoc"
                                            autocomplete="off"
                                            maxlength="20"
                                        />
                                        <small v-if="submitted && form.integracaoSoc && !form.code" class="p-error">Código SOC é obrigatório.</small>
                                    </div>
                                    <div class="field col-6">
                                        <label for="codigoExterno">Código Externo</label>
                                        <InputText
                                            id="codigoExterno"
                                            v-model.trim="form.codigoExterno"
                                            :class="{ 'p-invalid': submitted && form.integracaoTerceiros && !form.codigoExterno }"
                                            :required="form.integracaoTerceiros"
                                            autocomplete="off"
                                            maxlength="20"
                                        />
                                        <small v-if="submitted && form.integracaoTerceiros && !form.codigoExterno" class="p-error"
                                            >Código Externo é obrigatório.</small
                                        >
                                    </div>
                                </div>
                                <h5 class="mb-4 mt-5">Endereço</h5>
                                <div class="grid">
                                    <div class="field col-2">
                                        <label for="cep">CEP</label>
                                        <InputMask id="cep" v-model="form.cep" mask="99999-999"></InputMask>
                                    </div>
                                    <div class="field col-5">
                                        <label for="logradouro">Logradouro</label>
                                        <InputText id="logradouro" v-model="form.logradouro" />
                                    </div>
                                    <div class="field col-5">
                                        <label for="numero">Número</label>
                                        <InputText id="numero" v-model="form.numero" />
                                    </div>
                                    <div class="field col-4">
                                        <label for="complemento">Complemento</label>
                                        <InputText id="complemento" v-model="form.complemento" />
                                    </div>
                                    <div class="field col-4">
                                        <label for="bairro">Bairro</label>
                                        <InputText id="bairro" v-model="form.bairro" />
                                    </div>
                                    <div class="field col-4">
                                        <label for="cidade">Cidade</label>
                                        <DropDownCidade v-model="form.cidade" :modelValue="form.cidade" />
                                    </div>
                                </div>
                                <Divider align="center" />
                                <div clas="grid">
                                    <div class="field field-checkbox col-6">
                                        <label class="mr-2 ml-0" for="binary">Ativo</label>
                                        <InputSwitch v-model="form.ativo" />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Particularidades">
                                <div class="flex">
                                    <div class="field-checkbox">
                                        <InputSwitch id="enviar-anexo-manipulacao-alimentos" v-model="form.enviarAnexoManipulacaoAlimentos" />
                                        <label for="enviar-anexo-manipulacao-alimentos">Enviar anexo manipulação de alimentos</label>
                                    </div>
                                    <div class="field-checkbox ml-5">
                                        <InputSwitch
                                            id="apenasFuncionariosInativosExameDemissional"
                                            v-model="form.apenasFuncionariosInativosExameDemissional"
                                        />
                                        <label for="apenasFuncionariosInativosExameDemissional">
                                            Incluir funcionários inativos e de férias para exame demissional.
                                        </label>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="field-checkbox">
                                        <InputSwitch id="desabilitar-questionario" v-model="form.desabilitarQuestionarioRisco" />
                                        <label for="desabilitar-questionario">Não preencher questionário de risco</label>
                                    </div>
                                    <div v-if="empresaPermiteBuscarProcedimentosNaoRealizados" class="field-checkbox ml-6" style="padding-left: 6px">
                                        <InputSwitch
                                            id="permiteBuscarProcedimentosNaoRealizados"
                                            v-model="form.permiteBuscarProcedimentosNaoRealizados"
                                        />
                                        <label for="permiteBuscarProcedimentosNaoRealizados">
                                            Permitir buscar procedimentos não realizados de outros exames ocupacionais, no periódico
                                        </label>
                                    </div>
                                </div>
                                <div class="field-checkbox">
                                    <InputSwitch id="asoRetido" v-model="form.asoRetido" />
                                    <label for="asoRetido">Permitir ASO Retido</label>
                                </div>
                                <div class="flex flex-column">
                                    <div class="field-checkbox">
                                        <InputSwitch
                                            id="permiteConvocacaoExames"
                                            v-model="form.permiteConvocacaoExames"
                                            @update:modelValue="abrirModalConfirmacao"
                                        />
                                        <label for="permiteConvocacaoExames">Permitir convocação de exames periódicos</label>
                                    </div>
                                    <div v-if="form?.permiteConvocacaoExames" class="field col-2 p-0">
                                        <label for="tempoConvocacao">Tempo para convocação (dias)</label>
                                        <Dropdown
                                            id="tempoConvocacao"
                                            v-model="form.tempoConvocacaoExames"
                                            :class="{ 'p-invalid': submitted && !form.tempoConvocacaoExames }"
                                            :options="[30, 60, 90]"
                                            placeholder="Selecione..."
                                        />
                                        <small v-if="submitted && !form.tempoConvocacaoExames" class="p-error"> Campo obrigatório. </small>
                                    </div>
                                </div>
                                <div class="flex flex-column">
                                    <div class="field-checkbox">
                                        <InputSwitch id="desabilitar-questionario" v-model="form.reagendarFaltaConsulta" />
                                        <label for="desabilitar-questionario">Reagendar falta consulta</label>
                                    </div>
                                    <div v-if="form?.reagendarFaltaConsulta" class="field col-2 p-0">
                                        <label for="limiteReagendamento">Limite de reagendamentos</label>
                                        <InputNumber
                                            v-model="form.limiteReagendamento"
                                            :pt="{
                                                incrementButton: { class: ['p-0'] },
                                                decrementButton: { class: ['p-0'] }
                                            }"
                                            showButtons
                                            mode="decimal"
                                        />
                                    </div>
                                </div>
                                <div class="field-checkbox">
                                    <InputSwitch id="usaReservaHorario" v-model="form.usaReservaHorario" />
                                    <label for="usaReservaHorario">Usa reserva de horários</label>
                                </div>

                                <div v-if="form?.usaReservaHorario" class="field-checkbox">
                                    <InputSwitch id="permiteAlterarMetodoReservaHorario" v-model="form.permiteAlterarMetodoReservaHorario" />
                                    <label for="permiteAlterarMetodoReservaHorario"
                                        >Permite alterar o método "Quem Avisa" em solicitações de reserva de horário</label
                                    >
                                </div>
                                <div class="field-checkbox">
                                    <InputSwitch id="enviaEmailFuncionarioSemHierarquia" v-model="form.enviaEmailFuncionarioSemHierarquia" />
                                    <label for="enviaEmailFuncionarioSemHierarquia">Envia email quando funcionário não tem hierarquia</label>
                                </div>
                                <div class="grid">
                                    <div class="field col-6">
                                        <label for="kitAtendimento">Kit atendimento</label>
                                        <Dropdown
                                            id="kitAtendimento"
                                            v-model="form.kitAtendimento"
                                            :options="enumKitAtendimento"
                                            :showClear="true"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Selecione..."
                                        />
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-6">
                                        <label for="agendaUtilizada">Agenda utilizada</label>
                                        <Dropdown
                                            id="agendaUtilizada"
                                            v-model="form.agendaUtilizada"
                                            :options="enumAgendaUtilizada"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Selecione..."
                                            :showClear="true"
                                        />
                                    </div>
                                </div>

                                <Divider v-if="form?.integracaoSoc && form?.id" align="left" class="m-4">
                                    <span>Configuração de Integração Gupy</span>
                                </Divider>
                                <div class="field-checkbox">
                                    <InputSwitch id="utilizaIntegracaoGupy" v-model="utilizaIntegracaoGupy" />
                                    <label for="utilizaIntegracaoGupy">Utiliza integração Gupy</label>
                                </div>
                                <div class="field w-3">
                                    <label for="companyNameGupy">Identificação Gupy</label>
                                    <InputText id="companyNameGupy" v-model="form.companyNameGupy" />
                                </div>

                              

                                <Divider align="left" class="m-4">
                                    <span>Integração funcionário Sênior</span>
                                </Divider>
                                <div class="grid">
                                    <div class="field-checkbox col-12">
                                        <InputSwitch id="integraFuncionarioSenior" v-model="form.integraFuncionarioSenior" />
                                        <label for="integraFuncionarioSenior">Integração Sênior(Funcionários)</label>
                                    </div>

                                    <div v-if="form.integraFuncionarioSenior" class="field col-6">
                                        <label for="emailIntegraFuncionarioSenior"> Email recebimento integração sênior </label>
                                        <InputText
                                            id="emailIntegraFuncionarioSenior"
                                            v-model.trim="form.emailIntegraFuncionarioSenior"
                                            :class="{
                                                'p-invalid': submitted && form.integraFuncionarioSenior && !form.emailIntegraFuncionarioSenior
                                            }"
                                            :required="form.integraFuncionarioSenior"
                                            autocomplete="off"
                                            maxlength="100"
                                        />
                                        <small
                                            v-if="submitted && form.integraFuncionarioSenior && !form.emailIntegraFuncionarioSenior"
                                            class="p-error"
                                        >
                                            Email obrigatório para integração.
                                        </small>
                                    </div>
                                </div>

                                <Divider align="left" class="m-4">
                                    <span>Configurações SESMT</span>
                                </Divider>
                                <div class="grid">
                                    <div class="field-checkbox col-12">
                                        <InputSwitch id="validacaoAutomaticaGro" v-model="form.validacaoAutomaticaGro" />
                                        <label for="validacaoAutomaticaGro">Validação automática GRO</label>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel :disabled="!form.eSocialAtivo" header="eSocial">
                                <h6 class="mb-4">Certificado digital</h6>
                                <div v-if="form.id" class="grid">
                                    <div class="col-12">
                                        <div class="field-radiobutton">
                                            <RadioButton
                                                v-model="form.eSocialCertificadoProprio"
                                                :value="false"
                                                inputId="city1"
                                                name="eSocialCertificadoProprio"
                                            />
                                            <label for="city1">Certificado da empresa (Por procuração)</label>
                                        </div>
                                        <div class="field-radiobutton">
                                            <RadioButton
                                                v-model="form.eSocialCertificadoProprio"
                                                :value="true"
                                                inputId="city2"
                                                name="eSocialCertificadoProprio"
                                            />
                                            <label for="city2">Configurar certificado próprio do cliente</label>
                                        </div>
                                    </div>

                                    <FormCertificado
                                        v-if="form.eSocialCertificadoProprio"
                                        :certificado="form.eSocialCertificado"
                                        :customerId="form.id"
                                    />
                                </div>
                                <div v-else class="grid">
                                    <div class="field col-12 mb-0">
                                        <p>Configure o certificado após <strong>salvar o cliente</strong>.</p>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </div>
                </template>
            </AppFormCadastro>
            <DialogConvocacao ref="dialogConvocacao" @cancelar="form.permiteConvocacaoExames = false" />
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getClientBase } from '@/services/http';
import { getCurrentCompany } from '@/services/store';
import { limitarCaracteres } from '../../services/auth';
import QuemAgendaAvisa from '../../enums/QuemAgendaAvisa';
import ClassificacaoCliente from '../../enums/ClassificacaoCliente';
import SituacaoCliente from '../../enums/SituacaoCliente';
import DropdownCnae from '../../components/input/DropdownCnae.vue';
import DropDownCidade from '../cidades/components/DropdownCidade.vue';
import DropDownGrupo from '../grupos/components/dropdown-grupos.vue';
import MultiSelectProdutosServicos from '../produtos-servicos/components/MultiSelectProdutosServicos.vue';
import FormCertificado from '../esocial/certificados/Form';
import GeradorAsoEnum from '../../enums/GeradorAsoEnum';
import EnumKitAtendimento from '../../enums/EnumKitAtendimento';
import EnumAgendaUtilizadaCliente from '../../enums/EnumAgendaUtilizadaCliente';
import DestinatarioViaAsoEnum from '../../enums/DestinatarioViaAsoEnum';
import AppInfoManual from '../../components/AppInfoManual.vue';
import DialogConvocacao from './components/DialogConvocacao.vue';

export default {
    components: { DropdownCnae, DropDownCidade, DropDownGrupo, MultiSelectProdutosServicos, FormCertificado, AppInfoManual, DialogConvocacao },
    data() {
        return {
            form: {
                kitAtendimento: Object.keys(EnumKitAtendimento).find((key) => EnumKitAtendimento[key] === EnumKitAtendimento.EMPRESA),
                ativo: true,
                agendaUtilizada: 'EMPRESA',
                permiteBuscarProcedimentosNaoRealizados: true
            },
            runSave: false,
            formData: null,
            companies: [],
            submitted: false,
            serviceGrupos: null,
            agendaAvisa: [],
            tipoInscricao: [{ value: 'CNPJ' }, { value: 'CPF' }],
            grupos: [],
            grupo: undefined,
            produtosServicosSelecionados: [],
            cnaes: [],
            grauRisco: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }],
            situacaoCliente: [],
            classificacaoCliente: [],
            usuarios: [],
            produtosServicosCliente: [],
            configAso: {},
            enumGeradorAso: [],
            enumKitAtendimento: [],
            enumAgendaUtilizada: [],
            enumDestinatarioViaAso: [],
            utilizaIntegracaoGupy: false,
            particularidadeConfigAso: false,
            empresaPermiteBuscarProcedimentosNaoRealizados: false
        };
    },
    created() {
        this.serviceGrupos = new BaseService('/grupos');
        this.service = new BaseService('/customers');
        this.$serviceCustomer = new BaseService('/customers/combo');
        this.$serviceCompanies = new BaseService('/companies');
        this.$serviceBase = getClientBase();
    },
    async mounted() {
        this.form.tipoInscricao = 'CNPJ';
        await this.loadCustomers();
        await this.quemAgendaAvisa();
        await this.loadGrupos();
        await this.loadUsuarios();
        await this.loadConfigAso();
        this.geradorAsoOptions();
        this.kitAtendimentoOptions();
        this.agendaUtilizadaOptions();
        this.destinatarioViaAsoOptions();
        this.classificacaoClienteOptions();
        this.situacaoClienteOptions();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar cliente' : 'Adicionar cliente';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        'form.cnae'(newValue) {
            this.form.riskLevel = newValue?.grau ? newValue.grau : null;
        },
        'form.companyNameGupy'(newValue) {
            if (newValue) {
                this.utilizaIntegracaoGupy = true;
            }
        },
        utilizaIntegracaoGupy(newValue) {
            if (!newValue) {
                this.form.companyNameGupy = null;
            }
        },
        'form.usaReservaHorario'() {
            if (!this.form.usaReservaHorario) {
                this.form.permiteAlterarMetodoReservaHorario = false;
            }
        },
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        },
        grupo() {
            this.form.idGrupo = this.grupo?.id;
            this.form.idGrupo = this.form.idGrupo === undefined ? null : this.form.idGrupo;
        }
    },
    methods: {
        classificacaoClienteOptions() {
            this.classificacaoCliente = Object.keys(ClassificacaoCliente).map(function (type) {
                return { label: `${ClassificacaoCliente[type]}`, value: `${type}` };
            });
        },
        situacaoClienteOptions() {
            this.situacaoCliente = Object.keys(SituacaoCliente).map(function (type) {
                return { label: `${SituacaoCliente[type]}`, value: `${type}` };
            });
        },
        geradorAsoOptions() {
            this.enumGeradorAso = Object.keys(GeradorAsoEnum).map(function (type) {
                return { label: `${GeradorAsoEnum[type]}`, value: `${type}` };
            });
        },
        kitAtendimentoOptions() {
            this.enumKitAtendimento = Object.keys(EnumKitAtendimento).map(function (type) {
                return { label: `${EnumKitAtendimento[type]}`, value: `${type}` };
            });
        },
        agendaUtilizadaOptions() {
            this.enumAgendaUtilizada = Object.keys(EnumAgendaUtilizadaCliente).map(function (type) {
                return { label: `${EnumAgendaUtilizadaCliente[type]}`, value: `${type}` };
            });
        },
        destinatarioViaAsoOptions() {
            this.enumDestinatarioViaAso = Object.keys(DestinatarioViaAsoEnum).map(function (type) {
                return { label: `${DestinatarioViaAsoEnum[type]}`, value: `${type}` };
            });
        },
        hasError() {
            if (this.form.integraFuncionarioSenior && !this.form.emailIntegraFuncionarioSenior) return true;
            if (!this.form.tradeName) return true;
            if (!this.form.inscricao) return true;
            if (!this.form.quemAgenda) return true;
            if (!this.form.quemAvisa) return true;
            if (!this.form.name) return true;
            if (this.form.numeroContrato && !this.form.dataAssinaturaContrato) return true;
            if (this.form.permiteConvocacaoExames && !this.form.tempoConvocacaoExames) return true;
            if (this.form.id && this.form.integracaoSoc && this.particularidadeConfigAso && !this.configAso.geradorAso) return true;
        },
        onChangeTipoInscricao() {
            this.form.inscricao = '';
        },
        setGrauRisco() {
            this.form.grauRisco = +this.form.cnae?.grau || null;
        },
        validarEmail(email) {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        formatarData(data) {
            const dataTimestamp = Date.parse(data);
            const novaData = new Date(dataTimestamp);

            const ano = novaData.getUTCFullYear();
            const mes = novaData.getUTCMonth() + 1;
            const dia = novaData.getUTCDate();
            const dataFormatada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano}`;

            return dataFormatada;
        },
        async salvarConfigAso() {
            if (!this.form?.id) return;
            if (this.form.integracaoSoc && this.particularidadeConfigAso) {
                try {
                    this.configAso.idCustomer = this.form.id;
                    delete this.configAso.id;
                    await this.$serviceBase.post('/configuracao-aso/createOrUpdateCustomer', this.configAso);
                    return;
                } catch (err) {
                    this.$toast.add({ severity: 'error', summary: 'Erro ao salvar particularidades de configurações de aso', life: 3000 });
                    return;
                }
            }
            await this.$serviceBase.delete(`/configuracao-aso/customer/${this.form.id}`);
        },
        async onBeforeSave(cbError) {
            this.submitted = true;
            this.form.produtosServicosCliente = [];
            if (this.form.integracaoTerceiros && !this.form.codigoExterno) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Cliente possui integração com terceiros, preencha o codigo externo',
                    life: 3000
                });
                return cbError();
            }
            if (!this.form.apelido) {
                this.form.apelido = this.form.name;
            }
            if (!this.form.desabilitarQuestionarioRisco) {
                this.form.desabilitarQuestionarioRisco = false;
            }
            this.produtosServicosSelecionados.forEach((element) => {
                this.form.produtosServicosCliente.push({ produtoServico: element });
            });
            await this.salvarConfigAso();
        },
        async onAfterSave(response) {
            this.submitted = false;
            this.form = { ...response.data };
            if (this.formData) {
                await this.uploadLogo(undefined);
            }
            this.$router.push(`/customers/${this.form.id}`);
        },
        async onLoadDataEdit(data) {
            this.form = data;

            if (this.form.dataAssinaturaContrato) {
                this.form.dataAssinaturaContrato = this.formatarData(this.form.dataAssinaturaContrato);
            }
            this.produtosServicosSelecionados = this.form.produtosServicosCliente.map((r) => r.produtoServico);
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                this.$toast.add({ severity: 'error', summary: 'Há campos obrigatórios não preenchidos', life: 3000 });
                return cbError();
            }
            if (this.form.tradeName.length < 5) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Informe no mínimo 5 caracteres na razão social',
                    life: 3000
                });
                return cbError();
            }

            return cbSuccess();
        },
        async loadCustomers() {
            const { data } = await this.$serviceCustomer.findAll({});
            this.customers = data;
        },
        async loadGrupos() {
            const { data } = await getClientBase().get('/grupos');
            this.grupos = data.items;
        },
        async loadUsuarios() {
            const { data } = await getClientBase().get('/users/combo');
            this.usuarios = data;
        },
        async loadConfigAso() {
            if (!this.$route.params.id) return;
            const { data } = await getClientBase().get(`/configuracao-aso/customer/${this.form.id}`);
            this.configAso = data != '' ? data : {};
            this.particularidadeConfigAso = true;
            if (data) return;

            const configAsoCompany = await getClientBase().get(`/configuracao-aso/company/${this.form.companyId}`);
            this.particularidadeConfigAso = false;
            this.configAso = configAsoCompany.data != '' ? configAsoCompany.data : {};
        },
        async quemAgendaAvisa() {
            const companyId = getCurrentCompany();
            const { data } = await this.$serviceCompanies.findById(companyId);
            this.empresaPermiteBuscarProcedimentosNaoRealizados = data.permiteBuscarProcedimentosNaoRealizados;
            const nomeReduzidoEmpresa = limitarCaracteres(data.name, 38);
            this.agendaAvisa = [
                { label: 'Solicitante', value: QuemAgendaAvisa.SOLICITANTE },
                { label: nomeReduzidoEmpresa, value: QuemAgendaAvisa.EMPRESA },
                { label: 'Usuário Escolhe', value: QuemAgendaAvisa.USUARIO_ESCOLHE }
            ];
        },
        async uploadLogo(event) {
            if (event) {
                this.formData = new FormData();
                this.formData.append('file', event.files[0]);
                this.formData.append('origem', 'customers');
                if (this.form.id) {
                    this.formData.append('origemId', this.form.id);
                }
                this.formData.append('tipo', 'LOGO');
            } else {
                this.formData.append('origemId', this.form.id);
            }
            if (!this.form.id) {
                this.$toast.add({ severity: 'info', summary: 'A logo será carregada ao finalizar a criação', life: 3000 });
                return;
            }
            try {
                const anexo = await this.$serviceBase.post('/anexo', this.formData);
                this.form.urlLogo = anexo.data.url;
                this.formData = null;
                if (!event) {
                    this.runSave = true;
                } else {
                    await this.onBeforeSave();
                }
                this.$toast.add({ severity: 'success', summary: 'Logo carregada com sucesso', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao carregar a logo', life: 3000 });
            }
        },
        async removerLogo() {
            try {
                const { data } = await this.$serviceBase.get(`/anexo/customers/` + this.form.id);
                const anexosRemover = data.filter((p) => p.tipo == 'LOGO');
                for await (const anexo of anexosRemover) {
                    await this.$serviceBase.delete(`/anexo/${anexo.id}`);
                }
                this.form.urlLogo = null;
                await this.onBeforeSave();
                this.$toast.add({ severity: 'success', summary: 'Logo removida com sucesso', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao remover a logo', life: 3000 });
            }
        },
        abrirModalConfirmacao(valor) {
            if (!valor) return;
            this.$refs.dialogConvocacao?.abrir(this.form.id);
        }
    }
};
</script>

<style lang="scss" scoped>
.user-photo-template {
    border: 1px solid #ced4da;
    border-style: solid;
    width: 70px;
    height: 70px;
    align-self: center;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.user-photo {
    width: initial;
    height: inherit;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>
